@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  #__next {
    @apply bg-white z-0 relative;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;

    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;

    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;

    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;

    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;

    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;

    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;

    --radius: 0.5rem;

    --link-color: #2A97FB;
    --link-color-hover: #0C6BC2;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;

    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;

    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;

    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;

    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;

    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;

    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;

    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.header-banner .top-gap-with-banner {
  top: 100px;
}

video{
  pointer-events: none;
}
h1{
  color: var(--dark);
  text-align: center;
  font-family: var(--font-formula);
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin: 0;
  user-select: none;
}
h2{
  font-family: var(--font-formula);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  user-select: none;
  margin: 0;
}
h3{
  font-family: var(--font-formula);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  user-select: none;
  margin: 0;
}
h4{
  font-family: var(--font-formula);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%; /* 37.4px */
  letter-spacing: 0.3px;
  user-select: none;
  margin: 0;
}
h5{
  font-family: var(--font-formula);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%; /* 37.4px */
  letter-spacing: 0.3px;
  user-select: none;
  margin: 0;
}
h6{
  font-family: var(--font-formula);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%; /* 37.4px */
  letter-spacing: 0.3px;
  user-select: none;
  margin: 0;
}
p{
  color: var(--dark);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  user-select: none;
  margin: 0;
}

p, li, button, input, input::placeholder, textarea, textarea::placeholder {
  font-family: var(--font-jamjuree), var(--font-onest);
}

a{
  user-select: none;
  color: var(--link-color);
}
a:hover{
  color: var(--link-color-hover);
  text-decoration-line: underline;
}
a:visited{
  color: var(--link-color);
}
a:visited:hover{
  color: var(--link-color-hover);
}
span.italic{
  font-style: italic;
}
span.bold{
  font-weight: 600;
}
span.purple{
  color: var(--purple);
}
span.white{
  color: var(--white);
}
span.blue{
  color: var(--blue);
}
span.yellow{
  color: var(--yellow);
}
span.grey{
  color: var(--grey-font);
}
span.green{
  color: var(--green);
}
span.gradient{
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}
:root{
  --white: #ffffff;
  --light: #F1F6F6;
  --dark: #05050D;
  --hower-dark: #1B3039;
  --green: #33FF71;
  --purple: #A498FF;
  --blue: #75F3FB;
  --yellow: #DFF800;
  --dark-font: rgba(5, 5, 13, 0.70);
  --grey: rgba(255, 255, 255, 0.60);
  --grey-font: rgba(5, 5, 13, 0.30);
  --left-right-padding: 72px;
  --left-right-padding-mobile: 16px;
  --top-bottom-padding: 100px;
  --image-scroll-card-paddind: 60px 70px;
  --gradient: linear-gradient(40deg, rgba(145,171,255,1) 10%, rgba(67,243,254,1) 35%, rgba(51,255,113,1) 65%, rgba(142,252,56,1) 90%);
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--font-jamjuree), var(--font-onest), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}

main {
  padding: 120px var(--left-right-padding) 100px;
}
@media (max-width: 1024px) {
  .mobile main{
    padding: 100px var(--left-right-padding-mobile) 60px;
  }
}
body.desktop .mobile,
body.mobile .desktop{
  display: none!important;
}
header.header{
  position: fixed;
  width: 100%;
  z-index: 100;
}
.mobile.right-menu{
  position: fixed;
  z-index: 9999999;
  right: 0;
  top: 0;
  height: 100dvh;
  width: 100%;
}
.mobile.right-menu .overlay{
  content: ' ';
  height: 100dvh;
  width: 100%;
  background: #0000004a;
  position: fixed;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.mobile.right-menu .animate__animated{
  width: calc(100% - 50px);
  right: 0;
  position: absolute;
  background: var(--light);
  height: 100%;
  z-index: 5;
  animation-duration: 300ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobile.right-menu ul{
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  list-style: none;
  font-size: 24px;
  padding: 0;
  align-items: center;
  height: 100%;
}
.mobile.right-menu ul a.selected{
  font-weight: 700;
}
.mobile.right-menu ul li{
  width: 100%;
  display: flex;
}
.mobile.right-menu ul li > a{
  text-decoration: none;
  color: var(--dark);
  text-align: center;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  padding: 20px 36px;
  box-sizing: border-box;
}
.mobile.right-menu .button-container{
  margin-top: 40px;
  padding: 0 36px;
  box-sizing: border-box;
  width: 100%;
}
.mobile.right-menu ul a:focus{
  background-color: var(--white);
}
.mobile.right-menu .button-container a{
  text-decoration: none;
}
.mobile.right-menu .green-button{
  font-size: 20px;
  font-weight: 600;
  height: 70px;
  padding: 22px 38px;
  width: 100%;
}
header.header .header-block{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px var(--left-right-padding);
}
.header-block > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
header.header .header-block .links{
  margin: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
}
body.mobile header.header .header-block .links{
  padding: 0;
  gap: 20px;
}
header.header .header-block .links a{
  color: var(--white);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  transition: color .3s ease;
}
header.header .header-block .links a:hover{
  cursor: pointer;
  color: rgba(255, 255, 255, 0.50);
}
header.header svg:hover{
  cursor: pointer;
}
header.header .header-block .header-logo path:nth-child(1),
header.header .header-block .header-logo path:nth-child(2){
  fill: var(--white);
  transition: fill .3s ease;
}
header.header .header-block .header-logo:hover path:nth-child(1),
header.header .header-block .header-logo:hover path:nth-child(2){
  fill: var(--green);
}
header.header .green-button{
  padding: 6.5px 12px 7.5px 13px;
  font-size: 13px;
  font-weight: 600;
  height: 30px;
  min-width: 135px;
  width: 135px;
}
header.header .header-block .links{
  margin: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
}
header.header .header-block .links li{
  align-self: stretch;
  align-items: center;
  display: flex;
}
header.header .header-block .links a{
  color: var(--white);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
header.header .header-block .links a:hover{
  cursor: pointer;
  color: rgba(255, 255, 255, 0.50);
}
header.header .header-block .links a.selected{
  font-weight: 700;
  color: var(--green);
}
header.header .header-block .links a.selected:hover{
  cursor: default;
}
body{
  margin: 0;
  overflow-x: hidden;
}
/*.hidden{*/
/*  display: none!important;*/
/*}*/
.hidden-smooth{
  visibility: hidden;
  opacity: 0!important;
}
.green-button{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  color: var(--dark);
  border-radius: 5px;
  border: none;
  background: var(--green);
  text-align: center;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 1.063rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 0 79px;
  overflow: hidden;
  height: 50px;
  flex-direction: column;
  position: relative;
}
@media (min-width: 768px) {
  .green-button {
    font-size: 1.25rem;
    height: 60px;
  }
}
.green-button svg{
  -webkit-transition: transform 300ms ease-in-out;
  -moz-transition: transform 300ms ease-in-out;
  -o-transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
}
.green-button:hover svg{
  transform: translateX(10px);
}
.green-button:hover {
  cursor: pointer;
}
.green-button .button-text{
  z-index: 1;
  display: flex;
  gap: 15px;
  align-items: center;
}
.green-button .gradient{
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  -webkit-transition: opacity 300ms ease-in-out;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  background: linear-gradient(40deg, rgba(145,171,255,1) 10%, rgba(67,243,254,1) 35%, rgba(51,255,113,1) 65%, rgba(142,252,56,1) 90%);
}
.green-button:hover .gradient{
  opacity: 1;
}
.articles-title{
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 600px;
  gap: 20px;
}
.tag-page .articles-title{
  max-width: none;
}
.tag-page .articles-title .content-tags{
  max-width: 30%;
}
.articles-title h1{
  color: var(--dark);
  font-family: var(--font-formula);
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  text-align: start;
  line-height: 160%; /* 28.8px */
}
.articles{
  flex-wrap: wrap;
  display: flex;
  gap: 5%;
}
.published-date{
  color: rgba(5, 5, 13, 0.30);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.article.first{
  display: flex;
  flex-direction: column;
  gap:40px;
}
.article .image-block img{
  border-radius: 10px;
}
.article h6,
.article h6 a{
  color: var(--dark);
  font-family: var(--font-formula);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin: 10px 0;
  text-decoration: none;
}
.article h6 a:hover{
  text-decoration: underline;
}
.article .title-block{
  width: 50%;
}
.article .content-block{
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.article .content-block > div{
  display: flex;
  max-height: 50%;
  overflow: hidden;
}
.content-tags{
  flex-direction: row;
  gap: 8px;
  align-items: start;
  flex-wrap: wrap;
}
.content-tags .tag{
  padding: 6px 21px;
  border-radius: 3px;
  background: var(--light);
  color: var(--dark, #05050D);
  text-align: center;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.article .content-block p{
  color: rgba(5, 5, 13, 0.70);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}
.article-author-name p{
  font-size: 14px;
  font-weight: 600;
}
.article-author{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.article-author .article-author-avatar{
  align-items: center;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
}
.article.next .image-block .eye-icon path{
  fill: var(--white);
  fill-opacity: 1;
}
.article.next .published-date{
  color: var(--white);
  font-size: 14px;
}
.article.next .article-author .article-author-name p:first-child a{
  color: var(--dark);
}
.article-page .article-author .article-author-avatar img,
.authors-page .article-author .article-author-avatar img,
.article.next .article-author .article-author-avatar img{
  transition: transform 300ms ease-in-out;
}
.article-page .article-author .article-author-avatar img:hover,
.authors-page .article-author .article-author-avatar img:hover,
.article.next .article-author .article-author-avatar img:hover{
  transform: scale(1.1);
}
.article .wrapper-block{
  display: flex;
  flex-direction: row;
  gap: 24px;
  max-height: 245px;
  overflow: hidden;
}
.article.next{
  width: 30%;
  gap: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  text-decoration: none;
}
.authors-page .articles .article.next{
  margin-top: 20px;
}
.authors-page .author-row:not(:first-child){
  margin-top: 60px;
}
.article.next .image-block img{
  height: 32vh;
  object-fit: cover;
}
.article.next .content-block > div{
  max-height: none;
}
.article.next .wrapper-block{
  flex-direction: column;
  height: auto;
  gap: 5px;
  max-height: none;
}
.article.next .title-block{
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.article.next .title-block h6:hover{
  text-decoration: underline;
}
.article.next .image-block{
  border-radius: 10px;
  overflow: hidden;
}
.article.next .image-block img{
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}
.article.next .image-block:hover img{
  transform: scale(1.1);
}
.article.next .title-block h6{
  margin: 0;
}
.article.next .title-block h6,
.article.next .title-block h6 a{
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.article.next .content-block{
  width: 100%;
}
.article.next .content-block a,
.article.next .content-block p{
  max-height: 150px;
  text-decoration: none;
}
.load-more {
  margin: 40px 0 0;

}
main.article-page{
  padding-bottom: 0;
}
main.tags-page{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
main.tags-page .tag-cloud{
  max-width: 80%;
  text-align: center;
}
.article-page article{
  margin: auto;
}
.article-page > h1{
  text-align: start;
  max-width: 650px;
  margin: auto;
}
.article-page h1{
  color: var(--dark);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.article-page h2{
  color: var(--dark);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}
.article-page h3{
  color: var(--dark);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}
.article-page h4{
  color: var(--dark);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}
.article-page h5{
  color: var(--dark);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  line-height: 160%;
}
.article-page h6{
  color: rgba(5, 5, 13, 0.50);
  text-align: center;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin: 0 auto 30px;
}
.article-page a{
  font-family: var(--font-jamjuree), var(--font-onest);
  font-style: normal;
  line-height: 150%;
}
.article-page h6 a{
  font-size: 12px;
  font-weight: 600;
}
.article-page ul{
  list-style: disc;
  padding-inline-start: 40px;
}
.article-page ol{
  list-style: auto;
  padding-inline-start: 40px;
}
.article-page p,
.article-page li{
  color: #05050D;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 650px;
}
.article-page .author-container p a,
.article-page .author-container p a:hover{
  color: var(--dark);
}
.article-page li{
  margin: 30px auto;
}
.article-page .article-publish{
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 650px;
  margin: auto;
  align-items: center;
}
.article-author-name p{
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.article-author-name p .underline{
  text-decoration: underline;
}
.article-page .content-tags{
  display: flex;
}
.article-page .author-container{
  max-width: 650px;
  margin: 10px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.article-page .author-container.second{
  margin-top: 24px;
  margin-bottom: 70px;
}
.article-page .author-container .share{
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  background-color: var(--light);
  border-radius: 50px;
  padding: 7px 12px 7px 7px;
}
.article-page .author-container .share p{
    color: #C1C1C1;
    text-align: center;
    font-family: var(--font-jamjuree), var(--font-onest);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.article-page .image-block{
  max-width: 650px;
  margin: 20px auto 15px;
}
.article-page .image-block img{
  border-radius: 20px;
}
.article-page article > div > *{
  max-width: 650px;
  margin: 20px auto;
}
.article-page article img{
  max-width: 650px;
  display: block;
  margin: auto;
  border-radius: 20px;
}
footer.containerFooter{
  display: flex;
  flex-direction: column;
  background: var(--dark);
  gap: 35px;
  padding: 30px var(--left-right-padding) 50px;
  margin: 0 0 -20px;
}
footer .links-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
footer .links-container .left p{
  font-size: 14px;
  color: rgba(255, 255, 255, 0.30);
  margin-top: 15px;
}
footer .links-container > div{
  width: 25%;
}
footer .links-container .center ul{
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 0;
  padding: 0;
}
footer .links-container .center ul li{
  text-align: center;
}
footer .links-container .center ul li a{
  color: var(--white);
  text-align: center;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 16px;
  text-decoration: none;
  transition: color .3s ease;
}
footer .links-container .center ul li a:hover{
  color: rgba(255, 255, 255, 0.30);
}
footer .links-container .right{
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  gap: 30px;
}
footer .social-container{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
footer a.email-address{
  color: var(--white);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-style: normal;
  font-weight: 400;
  opacity: .3;
  transition: .3s ease opacity;
}
footer a.email-address:hover{
  color: var(--white);
  opacity: 1;
}
footer .social-container .invisible,
footer .social-container .cofollow{
  flex: 1;
}
footer .social-container .cofollow p{
  text-align: start;
}
footer .social-container .privacy a{
  transition: color .3s ease;
}
footer .social-container .cofollow .social-icons{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 35px;
  justify-content: start;
}
footer .social-container .cofollow .social-icons a{
  display: flex;
  align-items: center;
}
footer .social-icons svg:hover{
  cursor: pointer;
}
footer .social-icons a svg path{
  transition: all .3s ease;
}
.mobile footer .social-container .cofollow .social-icons .linkedin svg path,
.mobile footer .social-container .cofollow .social-icons .instagram svg g g path,
.mobile footer .social-container .cofollow .social-icons .twitter svg g path,
.mobile footer .social-container .cofollow .social-icons .youtube svg g path,
.mobile footer .social-container .cofollow .social-icons .facebook svg g path{
  fill: var(--green);
}
footer .social-icons .linkedin:hover svg path{
  fill: #406AFD;
}
footer .social-icons .instagram:hover svg g g path{
  fill: #FC75FF;
}
footer .social-icons .twitter:hover svg g path{
  fill: #8D9A98;
}
footer .social-icons .youtube:hover svg g path{
  fill: #FF3427;
}
footer .social-icons .facebook:hover svg g path{
  fill: #3CADFF;
}
footer .social-container .privacy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  flex: 2;
}
footer .social-container .privacy .privacy-links{
  display: flex;
  gap: 20px;
  justify-content: center;
}
footer .social-container .privacy .privacy-links a{
  color: rgba(255, 255, 255, 0.30);
  text-align: center;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
}
footer .social-container .privacy .privacy-links a:hover{
  color: var(--white);
  cursor: pointer;
}
footer .social-container .privacy p,
footer .social-container .cofollow p{
  color: rgba(255, 255, 255, 0.30);
  font-size: 14px;
}
section.request-demo-form-section,
section.related-articles{
  margin: 0 calc(-1* var(--left-right-padding));
  padding: 70px var(--left-right-padding);
}
section.request-demo-form-section{
  background: var(--light);
}
section.related-articles{
  background: var(--dark);
  flex-wrap: wrap;
  display: flex;
  gap: 5%;
  flex-direction: column;
}
section.related-articles h5{
  color: var(--white);
  font-family: var(--font-formula);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-align: start;
}
section.related-articles .published-date{
    color: rgba(255, 255, 255, 0.40);
}
section.related-articles .article-author{
  margin: 0;
  max-width:none;
}
section.related-articles h6{
  color: var(--white);
}
section.related-articles .article-author-name p:first-child,
section.related-articles .article-author-name p:first-child a{
  color: var(--white)!important;
}
section.related-articles .article-author-name p:last-child span{
  color: rgba(255, 255, 255, 0.40);
}
section.related-articles .article{
  margin-top: 0;
}
section.related-articles .article h6 a{
  color: var(--white);
}
section.related-articles .content-tags .tag{
  color: rgba(255, 255, 255, 0.50);
  background: rgba(241, 246, 246, 0.15);
}
section.related-articles .content-tags .tag:hover{
  background: rgba(241, 246, 246, 0.25);
}
div.request-demo-form-block{
  max-width: 560px;
  margin: auto;
}
section.request-demo-form-section .button-container{
  margin: 30px auto 0;
}
section.request-demo-form-section h2{
  color: var(--dark);
  text-align: center;
  font-family: var(--font-formula);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}
section.request-demo-form-section h3{
  color: var(--dark);
  text-align: center;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.flex-column{
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
form.dark .iti__country-name{
  color: #FFF;
}
form.dark .iti__country-list{
  background-color: #19222A;
  border: 1px solid rgba(255, 255, 255, 0.10);
}
.iti.iti--allow-dropdown{
  width: 100%;
}
.iti__selected-flag{
  padding: 0 15px;
}
.flex-column svg{
  position: absolute;
  right: 15px;
  top: 50px;
  opacity: 0;
}
.flex-column input.valid+svg,
.flex-column.phone.valid svg{
  opacity: 1;
  animation: svg 300ms ease-in alternate;
}
input[name="phone"]{
  width: 100%;
}
@keyframes svg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
label{
  color: var(--dark);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
select{
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #C7CFD3;
}
body.mobile form textarea,
body.mobile form .select__single-value,
body.mobile form input{
  font-size: 16px;
}
input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus{
  border: 1px solid var(--dark);
  outline: none;
}
.iti--allow-dropdown{
  width: 100%;
}
.iti__country-name{
  font-family: var(--font-jamjuree), var(--font-onest);
}
.iti__country-list li{
  margin: 0;
}
input::placeholder{
  color: rgba(5, 5, 13, 0.20);
}
input.uk-form-danger,
input.invalid,
select.invalid + .select-selected{
  border: 2px solid #FC3434;
  background: #FFF1F1;
  color: #FC3434;
}
input.uk-form-danger::placeholder,
input.invalid::placeholder{
  color: #FC3434;
}
fieldset{
  border: none;
  margin: 40px 0 30px;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 30px;
}
textarea{
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #C7CFD3;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
textarea::placeholder{
  color: rgba(5, 5, 13, 0.20);
}
.form{
  display: flex;
  flex-direction: column;
}
.tooltip{
  position: absolute;
  right: 10px;
  top: 90%;
  padding: 10px;
  color: #FC3434;
  text-align: center;
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #fff;
  filter: drop-shadow(0px 1px 21px rgba(191, 127, 127, 0.25));
  border-radius: 10px;
}
.tooltip:after{
  content: " ";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #FFF transparent;
  transform: rotate(0deg);
  position: absolute;
  top: -10px;
  filter: drop-shadow(0px 1px 21px rgba(191, 127, 127, 0.25));
  left: calc(50% - 10px);
}

.field-validation-popup{
  position: absolute;
  padding: 0 20px;
  left: calc(100% + 15px);
  top: 0;
  border-radius: 5px;
  width: 17vw;
  height: 100%;
  display: flex;
  align-items: center;
}
.mobile .field-validation-popup{
  left: auto;
  right: -8px;
  top: -20px;
  width: 50%;
  padding: 0 12px;
}
.field-validation-popup p{
  color: var(--white);
  font-family: var(--font-jamjuree), var(--font-onest);
  font-size: .85vw; /* 12px; */
  font-style: normal;
  font-weight: 400;
}
.mobile .field-validation-popup p {
  font-size: 11px;
}
.desktop .field-validation-popup:before{
  content: '';
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: calc(50% - 10px);
  left: -10px;
}
div[class*='field_incorrect'] .field-validation-popup{
  background: #CE3B44;
}
div[class*='field_incorrect'] .input{
  color: #CE3B44;
}
div[class*='field_corporate'] .input{
  color: #3B99CE;
}
.desktop div[class*='field_corporate'] .field-validation-popup:before{
  border-right: 10px solid #3B99CE;
}
div[class*='field_corporate'] .field-validation-popup{
  background: #3B99CE;
}
div[class*='field_incorrect'] .input{
  background: #221c24;
}
div[class*='field_incorrect'] .input:hover{
  background: #221c24;
}
.mobile .select__placeholder{
  font-size: 16px;
}

@media
  /* Extra small devices (phones, 600px and down) */
only screen and (max-width: 600px),
  /* ----------- iPhone 4 and 4S Landscape ----------- */
only screen and (min-device-width: 320px)
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape),
  /* ----------- iPhone 5, 5S, 5C and 5SE Landscape ----------- */
only screen and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape),
  /* ----------- iPhone 6, 6S, 7 and 8 Landscape ----------- */
only screen and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape),
  /* ----------- iPhone 6+, 7+ and 8+ ----------- */
only screen and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3),
  /* ----------- iPhone X ----------- */
only screen and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3),
  /* ----------- iPhone XR ----------- */
only screen and (min-device-width: 414px)
and (max-device-width: 896px)
and (-webkit-min-device-pixel-ratio: 2),
  /* ----------- Galaxy S3 ----------- */
screen and (device-width: 360px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 2),
  /* ----------- Galaxy S4, S5 and Note 3 ----------- */
screen and (device-width: 320px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 3),
  /* ----------- Galaxy S6 & Google Pixel XL ----------- */
screen and (device-width: 360px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 4),
  /* ----------- HTC One & Google Pixel ----------- */
screen and (device-width: 360px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 3),
  /* ----------- Nexus 4 ----------- */
screen and (device-width: 384px)
and (device-height: 592px)
and (-webkit-device-pixel-ratio: 2),
  /* ----------- Nexus 5 ----------- */
screen and (device-width: 360px)
and (device-height: 592px)
and (-webkit-device-pixel-ratio: 3),
  /* ----------- Nexus 6 and 6P ----------- */
screen and (device-width: 360px)
and (device-height: 592px)
and (-webkit-device-pixel-ratio: 4),
  /* ----------- Windows Phone ----------- */
screen and (device-width: 480px)
and (device-height: 800px),
  /* ----------- iPad 1, 2, Mini and Air ----------- */
only screen and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 1),
  /* ----------- iPad 3, 4 and Pro 9.7" ----------- */
only screen and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 2) {
  h1 {
    font-size: 25px;
    text-align: start;
  }
  .green-button{
    padding: 18px 50px 17px;
  }

  header.header .header-block {
    padding: 20px var(--left-right-padding-mobile);
  }
  .close{
    position: absolute;
    top: 20px;
    right: 16px;
  }
  .right-menu-logo{
    position: absolute;
    top: 20px;
    left: 16px;
  }
  .articles-title h1{
    font-size: 14px;
  }
  .article.next .image-block{
    margin-bottom: 24px;
    border-radius: 10px;
    position: relative;
  }
  .articles .article .image-block img{
    border-radius: 10px;
  }
  .articles .article div.wrapper-block{
    flex-direction: column;
    max-height: none;
  }
  .articles .article div.title-block{
    width: 100%;
  }
  .articles .article div.content-block{
    width: 100%;
  }
  .articles .article div.content-block > div{
    max-height: none;
  }
  .articles .article div.content-block p{
    max-height: 150px;
  }
  .articles .article.next{
    width: 100%;
    margin-top: 22px;
    gap: 10px;
  }
  .load-more{
    margin-bottom: 0;
  }
  .load-more button{
    width: 100%;
  }
  main.tags-page .tag-cloud{
    max-width: 100%;
  }
  .article-page .article-publish{
    display: flex;
    justify-content: space-between;
  }
  .article-page .tag,
  .article-page .published-date{
    font-size: 12px;
  }
  .article-page .image-block img{
    border-radius: 10px;
  }
  .article-page h1{
    font-size: 25px;
    line-height: 160%;
    font-weight: 700;
  }
  .article-page h2{
    font-size: 20px;
    font-style: normal;
  }
  .article-page h3{
    font-size: 18px;
    line-height: 160%;
  }
  .article-page h5{
    font-size: 16px;
  }
  .article-page h6,
  .article-page h6 a{
    font-size: 12px;
  }
  .article-page p,
  .article-page li{
    font-size: 18px;
    font-weight: 400;
    line-height: 160%;
  }
  .article-page li{
    margin: 10px auto;
  }
  .article-author{
    gap:8px;
  }
  .article-author .article-author-avatar{
    align-self: start;
  }
  .article-author-name p{
    font-size: 14px;
    font-weight: 600;
  }
  .article-author-name p a{
    text-decoration: underline;
  }
  .article-page ol,
  .article-page ul{
    padding-left: 30px;
  }
  .article-page .image-block .published-date{
    color: var(--white);
    font-size: 14px;
  }
  .article-page .image-block{
    margin: 16px auto 15px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  .article-page .image-block .eye-icon path{
    fill: var(--white);
    fill-opacity: 1;
  }
  .article-page article img{
    border-radius: 10px;
    width: 100%;
  }
  .article-page .author-container .share{
    background: none;
    padding: 0;
    align-items: center;
  }
  .article-page .author-container.second .share{
    justify-content: center;
  }
  .article-page .author-container.second{
    margin-bottom: 50px;
  }
  .article-page .author-container.second .published-date{
    font-size: 14px;
  }
  .article-page .author-container .share .share-a-link circle{
    fill: var(--light)
  }
  .article-page .quoteblock .quotetext span{
    font-size: 16px;
    line-height: 160%;
  }
  .article-page .author-container{
    flex-direction: column;
    gap: 16px;
  }
  .tag-page .articles-title .content-tags{
    max-width: none;
  }
  .container-global{
    padding: 50px var(--left-right-padding-mobile) 30px;
    gap: 32px;
  }
  section.request-demo-form-section,
  section.related-articles{
    margin: 0 calc(-1* var(--left-right-padding-mobile));
    padding: 70px var(--left-right-padding-mobile);
  }
  section.related-articles h5{
    color: #FFF;
    font-family: var(--font-formula);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-align: start;
  }
  section.related-articles .articles .article{
    margin-top: 10px;
  }
  section.related-articles .articles .article:not(:first-of-type) {
    margin-top: 40px;
  }
  footer.containerFooter{
    padding: 6px var(--left-right-padding-mobile) 30px;
    gap: 46px;
  }
  footer .email-address a,
  footer .email-address p{
    opacity: 1;
  }
  footer .links-container{
    flex-direction: column;
    gap: 46px;
  }
  footer .links-container > div{
    width: 100%;
  }
  footer .links-container .left p{
    font-size: 14px;
    color: var(--white);
    font-weight: 500;
    line-height: 20px;
  }
  footer .links-container .left .email-address{
    margin-top: 24px;
  }

  footer .links-container .left .email-address p{
    margin: 0;
    color: #fff;
    opacity: .3;
  }
  footer .links-container .center ul{
    padding: 0;
    gap: 32px;
  }
  footer .links-container .center ul li{
    text-align: left;
  }
  footer .links-container .center ul li a{
    font-size: 18px;
    font-weight: 500;
    user-select: none;
  }
  footer .links-container .right{
    flex-direction: row;
    justify-content: space-between;
  }
  footer .links-container .right img{
    pointer-events: none;
  }
  footer .social-container{
    flex-direction: column;
    gap: 24px;
  }
  footer .social-container .cofollow p{
    text-align: start;
    font-weight: 500;
  }
  footer .social-container .cofollow .social-icons{
    justify-content: start;
    margin-top: 16px;
  }
  footer .social-container .privacy{
    gap: 16px;
  }
  footer .social-container .privacy p{
    text-align: start;
    font-weight: 500;
  }
  footer .social-container .privacy .privacy-links{
    justify-content: start;
  }
  footer .social-container .invisible{
    display: none;
  }
}

.loader{
  top:0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark);
  z-index: 9999;
}
.blinker {
  animation: blink 1.5s linear infinite;
}
#quidget a{
  color: var(--link-color);
  text-decoration: underline;
}
#quidget ul,
#quidget ol,
#quidget dl {
  list-style: auto;
}
@keyframes blink {
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.15;
  }
  75% {
    opacity: 0.5;
  }
}
